#home {
    background-color: #000000;
}

.intro {
    min-height: 100vh;
}

.about {
    min-height: 100vh;
}

.title {
    font-size: 7rem;
}

.secondary-title {
    font-size: 5rem;
}

@media screen and (max-width: 876px) {
    .title {
        font-size: 5rem;
    }
    .secondary-title {
        font-size: 3rem;
    }
}

@media screen and (max-width: 576px) {
    .intro {
        padding-top: 2.5rem;
    }
    .title {
        font-size: 4rem;
    }
    .secondary-title {
        font-size: 2rem;
    }
}
#contact {
    background-color: #000;
    min-height: 100vh;
}

.my-icon {
    transition: all .2s ease-in-out;
}
  
.my-icon:hover {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
    cursor: pointer;
    text-decoration: none !important;
    color: #ffffff !important;
}

.my-coffee-button:hover {
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    #contact {
        padding-top: 2.5rem;
    }
}

@media screen and (max-width: 876px) {
    .contact-text {
        font-size: 1.8rem;
    }
    .my-icon {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 776px) {
    .contact-text {
        font-size: 2rem;
    }
}

@media screen and (max-width: 676px) {
    .contact-text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 376px) {
    .contact-text {
        font-size: 1.3rem;
    }
}
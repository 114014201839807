.pointer {
    cursor: pointer;
}

.phil-font {
    font-family: philosopher;
}

@font-face {
    font-family: philosopher;
    src: url(../fonts/Philosopher-Regular.ttf);
}

.phil-ital-font {
    font-family: philosopher-italic;
}

@font-face {
    font-family: philosopher-italic;
    src: url(../fonts/Philosopher-Italic.ttf);
}
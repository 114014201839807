#admin{
    background-color: #000000;
    padding-bottom: 3rem;
    min-height: 100vh;
}

.admin-btn {
    border-radius: 2.5px;
}

.project-form {
    background-color: #0c0c0c;
    border: 1px solid black;
    border-radius: 2.5px;
    padding: 2%;
}

.submit-button {
    min-width: 100%;
    border-radius: 2.5px;
}

.projects {
    background-color: #0c0c0c;
    border-radius: 2.5px;
    margin: 1% 0;
    transition: all .25s ease-in-out;
}

.project-image {
    width: 75%;
    height: auto;
}

.project-desc-pre {
    white-space: pre-line;
    font-family: "Maison Neue", Helvetica Neue, Helvetica, Arial, sans-serif !important;
    font-size: 16px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #ffffff;
}
#music {
    background-color: #000000;
}

.music-container {
    min-height: 100vh;
    min-width: 100vw;
}

.my-icon {
    transition: all .2s ease-in-out;
}
  
.my-icon:hover {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
    cursor: pointer;
    text-decoration: none !important;
    color: #ffffff !important;
}

@media screen and (max-width: 876px) {
    .my-display-1 {
        font-size: 4rem;
    }
    .my-display-3 {
        font-size: 2rem;
    }
    .my-display-5 {
        font-size: 2rem;
    }
    .my-icon {
        font-size: 1.75rem;
    }
}

@media screen and (max-width: 576px) {
    .my-display-1 {
        font-size: 2rem;
    }
    .my-display-3 {
        font-size: 1rem;
    }
    .my-display-5 {
        font-size: 1rem;
    }
    .my-icon {
        font-size: 1.5rem;
    }
}
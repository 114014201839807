.programming-container {
    min-height: 100vh;
}

@media screen and (max-width: 876px) {
    .programming-text {
        font-size: 1.5rem;
    }
} 

@media screen and (max-width: 576px) {
    .programming-text {
        font-size: 1rem;
    }
}